// Here you can add other styles
//.d-md-down-none{color:#ff004c;}

.c-sidebar-brand-full {
  color: gray("100");
  background-color: #1E5423;
}

.light-buttons {
  color: #2e8337;
}

.quantity-filter {
  margin-bottom: 1rem;
  font-weight: bold;
}

.c-sidebar-brand-whole {
  background-color: #26682d;
}

.MuiDataGrid-columnsContainer {
  background-color: #2e8337;
  color: #ffffff;
  text-align: center !important;
}

.MuiDataGrid-root .MuiDataGrid-cell {
  padding-left: 0.15rem !important;
  padding-right: 0.15rem !important;
  text-align: center !important;
}


.MuiDataGrid-root .MuiDataGrid-colCellTitleContainer {
  justify-content: center;
}

.MuiDataGrid-root .MuiDataGrid-cellWithRenderer {
  justify-content: center;
}

.MuiTableCell-root {
  font-size: 90% !important;
}

.headlines {
  color: #26682d;
}

.OddsFormInput {
  height: 1.15rem;
  padding: 0rem;
  font-size: 90%;
}

.MuiDataGrid-root {
  font-family: 'Times New Roman', Times, serif;
  font-size: 0.75rem !important;
}

.MuiDataGrid-colCellCheckbox .MuiSvgIcon-root {
  color: #ffffff;
}

.MuiDataGrid-root .MuiDataGrid-iconSeparator {
  opacity: 0;
}

.prefix__cls-1 {
  fill: #468532;
}

.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
  font-weight: bold;
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  border-radius: 15px;
}

.setting-list {
  background-color: #0d3f12 !important;
  color: #FFFFFF !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.titleRow {
  width: 140px;
}

.tableCol {
  width: 500px !important;
}

.form-check-input {
  margin-left: 0rem !important;
}

// Here you can add other styles
.firstBoxes {
  margin-right: 10px;
  margin-top: 10px;
}

.floatright {
  float: right;
}

.paddTopp {
  margin-top: 20px;
}

.marginrit {
  margin-right: 5px;
}

.buttonSize {
  font-size: xx-small;
  ;
}

.nopad {
  padding-left: 1px;
  padding-right: 1px;
}

.couponsleftpad {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.unsettleddatagrid {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  height: 400px;


}

.TestShop {
  color: darkblue;
  font-style: bold;
  font-size: x-large;
  margin-bottom: 5px;
}

.AdTerm {
  text-align: center;
  font-size: 5px;
}

.TeletextTitle {

  font-style: bold;
  font-size: x-large;
  margin-bottom: 15px;
  margin-top: 5px;
  margin-left: 5px;
}

.margTermBut {
  margin-top: 10px;
  margin-bottom: 10px;

}

.spaceabove {
  margin-top: 20px;
  text-align: center;
}

.margtop {
  margin-top: 20px;
}

.spaceabovetext {
  margin-top: 30px;
  text-align: center;
}

.spacebelow {
  margin-bottom: 20px;
  text-align: center;
}

.headRow {
  background-color: rgb(6, 219, 219);

}

div.cyantext {
  color: rgb(52, 186, 209)
}

div.whiteText {
  color: white;
}

.margleft {
  margin-left: 20px;
  float: left;
  text-align: justify;
}

.actualmargleft {
  margin-left: 20px;
  float: left;
  text-align: justify;
}

.goright {
  float: right;
  margin-bottom: 50;
}

.margtopbot {
  margin-top: 5px;
  margin-bottom: 5px;
}

.margtopp {
  margin-top: 5px;
}

.alligncenter {
  align-items: center;
}

.goup {
  padding-bottom: 20px;
}

.movefromleft {
  margin-left: 10px;
  padding-left: 10px;
}

.movelefttop {
  margin-left: 10px;
  margin-top: 10px;
}

.giatable {
  white-space: nowrap;
}

.printPDF {
  border: 1px solid black !important;
  font-size: 10px !important;
}

.printPDFNoBorder {
  border: none !important;
}

.header {
  text-align: center;
  width: 100%;
}

@media print {
  @page {
    size: portrait !important;
    margin: 0px 0px 20px !important;
  }
}


.tableborder {
  border-left-color: lightgray;
  border-left-style: groove;
  border-left-width: thin;
}

.tableborderright {
  border-right-color: lightgray;
  border-right-style: groove;
  border-right-width: thin;
}

.tableborderTOP {
  border-top-color: lightgray;
  border-top-style: groove;
  border-top-width: thin;
  border-left-color: lightgray;
  border-left-style: groove;
  border-left-width: thin;
}

// .tableborderlast{
//   border-left-color: lightgray;
//   border-left-style: groove;
//   border-left-width: thin;
//   border-right-color: lightgray;
//   border-right-style: groove;
//   border-right-width: thin;
// }
// .tableborderlastTOP{
//   border-top-color: lightgray;
//   border-top-style: groove;
//   border-top-width: thin;
//   border-left-color: lightgray;
//   border-left-style: groove;
//   border-left-width: thin;
//   border-right-color: lightgray;
//   border-right-style: groove;
//   border-right-width: thin;
// }

// Here you can add other styles
.marginleft {
  margin-left: 1075px;
}

.MarginPoolsTeplateButton {
  margin-left: 15px;
  margin-bottom: 15px;
}

.MarginPoolsCinputButton1 {
  margin-left: 22px;
  margin-bottom: 15px;
  margin-top: 10px;
}

.MarginPoolsCinputButton {
  margin-left: 70px;
  margin-bottom: 15px;
  margin-top: 22px;
}

.MarginDropTeplateButton {
  margin-left: 5px;
  margin-top: 10px;
}

.MarginDropTeplateButton1 {
  margin-left: 5px;
  margin-top: 10px;
}

.MarginDropTeplateButton1 {
  margin-left: 10px;
  margin-top: 10px;
}

.MarginDropTeplateButton3 {
  margin-left: 37px;
  margin-top: 20px;
  margin-bottom: 30px
}

.MarginDropTeplateButton4 {
  margin-left: 37px;
  margin-top: 50px;
  margin-bottom: 30px
}

.ti {
  color: blue;
  font-size: large;
  margin-left: 15px;
}

.tableBord {
  border-left-color: white;
  border-left-style: groove;
  border-left-width: thin;
}

.ReactVirtualized__Table__headerRow{
  display: inline-flex;
  background-color: #2e8337;
}

.ReactVirtualized__Table__row{
  display: flex;
}
.ReactVirtualized__Table__rowColumn{
  display: inline-flex;
}

.ReactVirtualized__Grid__innerScrollContainer{
  width: 100% !important;
}

.id_flex{
  flex: 1;
}
.Group_flex{
  flex: 4;
}

.tableBord2 {
  border-left-color: white;
  border-left-style: groove;
  border-left-width: thin;
  padding:0px 0px 0px 0px
}

.tableFooterCss {
  background-color: #dae9db;
  border-color: black;
  border-style: groove;
  border-width: thin;
  font-weight: bold !important;
  font-size: 15px !important;
  color: black !important;
}

.tableBord1 {
  border-left-color: lightgrey;
  border-left-style: groove;
  border-left-width: thin;
  border-bottom-color: snow;
  background-color: snow;
  border-bottom-width: thin;
  border-bottom-style: groove;
}

// Here you can add other styles
.Title {
  color: #3b0db8;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 25px;
  font-weight: bold;
}

.Title2 {
  color: #358ef3;
  margin-top: 50px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
}

.labels {
  margin-right: 10px;
  margin-left: 15px;
}

.input-numbers {
  box-sizing: border-box;
  width: 70px;
}

.margtopbot {
  margin-top: 35px;
  margin-bottom: 35px;
}

.input-boxes {
  box-sizing: border-box;
  width: 70px;
}

.input-dates {
  box-sizing: border-box;
  width: 130px;
}

.fit-picture {
  width: 30px;
  margin-top: 20px;
  margin-bottom: 15px;
  margin-left: 20px;
}

.cellBorder {
  border: 0.05mm solid rgba(69, 68, 70, 0.6);
  padding-left: 10px;
  padding-right: 10px;
  // line-height: 15px;
}

.headBorder {
  border: 0.05mm solid rgba(50, 50, 51, 0.6);
  padding-left: 10px;
  padding-right: 10px;
}

.rowheight {
  max-height: 20px;
}

.rules {
  border: 0.05mm solid rgba(50, 50, 51, 0.6);
  background-color: rgb(89, 175, 247);
  color: white;
  padding-left: 200px;

}

.actions {
  border: 0.05mm solid rgba(50, 50, 51, 0.6);
  color: white;
  background-color: rgb(33, 94, 145);
  padding-left: 200px;

}

.required-field {
  color: red;
  font-weight: bold;

}

.the_grey_card {
  background-color: rgb(240, 245, 240);
}

.MuiFormControl-fullWidth1{
  min-width: 300% !important;
}

.MainTableContainer{
  overflow-y: scroll;
  height: calc(var(--innerHeight) - var(--header_height) - var(--table_footer_height) - 2px);
  @media screen and (max-width: 992px) {    
    height: calc(var(--innerHeight) - var(--small_header_height) - var(--small_table_footer_height) - 2px);
  }
}
.MuiTableCell-root.CategoriesTableCell {
  padding: 2px;
}

// Here you can add other styles
//.d-md-down-none{color:#ff004c;}
// :root {
//   --header_height: 70px;
//   --table_footer_height: 60px
// }

.c-sidebar-brand-full {
  color: gray("100");
  background-color: #1E5423;
}
  
.light-buttons {
  color: #2e8337;
}
  
.quantity-filter {
  margin-bottom: 1rem;
  font-weight: bold;
}

.c-sidebar-brand-whole {
  background-color: #26682d;
}
.MuiDataGrid-columnsContainer {
  background-color: #2e8337;
  color: #ffffff;
}
.headlines {
  color: #26682d;
}
.MuiDataGrid-colCellCheckbox .MuiSvgIcon-root{
  color: #ffffff;
}

.MuiDataGrid-root .MuiDataGrid-iconSeparator{
  opacity: 0;
}

.prefix__cls-1 {
  fill:#468532;
}

.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1{
  font-weight: bold;
}

.MuiBadge-colorPrimary{
  background-color: #1E5423 !important;
}

.MuiFormControlLabel-label{
  font-size: 1rem !important;
}

.btn-money-group {
 border: solid 1px !important;
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  border-radius: 15px;
}
.float-right:focus {
  outline: none !important;
}

.MuiTableCell-stickyHeader {
  background-color: #2e8337 !important;
}

.pdfTable_head_row_cell{
  margin: 0px !important;
  padding: 0px !important;
  font-weight: 700;
  background-color: white !important;
  border: 1px solid black !important;
}

.pdfTable_head_row_fake_cell{
  margin: 0px !important;
  padding: 0px !important;
  background-color: white !important;
  border: 0px solid black !important;
  height: 30px;
}

.pdfTable_footer_row_fake_cell{
  margin: 0px !important;
  padding: 0px !important;
  background-color: white !important;
  border: 0px solid black !important;
}

.pdfTable_body_row_cell{
  margin: 0px !important;
  padding: 0px !important;
  font-weight: 500;
  background-color: white !important;
  border: 1px solid black !important;
}

.buttons_with_space{
  margin-right: 10px;
}
.modal-header .close{
  color: white;
}

.buttons_with_half_space{
  margin-right: 5px;
}
.footer_buttons{
  margin-right: 10px;
  background-color: white;
  :hover{
    background-color: inherit;
  }
}

.zero_margin_bottom{
  margin-bottom: 0px !important;
}

.aligned_row{
  align-items: center;
}


.zero_padding_margin{
  padding: 0px !important;
  margin: 0px !important;
  .hide_bettslip_column{
    @media screen and (max-width: 992px) {
      display: none;
    }
  }
  .centralize_when_small{
    // justify-content: inherit;
    align-items: center;
    background-color: #2e8337;
    @media screen and (max-width: 992px) {
      justify-content: flex-start;
    }
  }
  .theHeaderClass{
    // background-color: #dddddd;
    background-color: #fdbf33;
    height: var(--header_height);
    // border-right-width: inherit;
    border-right-color: #1d5222;
    border-right-style: "solid";
    @media screen and (max-width: 992px) {    
      // background-color: #fdbf33;
      height: var(--small_header_height);
    }
  }

  .centerLogo{
    color: #ffffff;
    margin-left: 30px;
    @media screen and (max-width: 992px) {    
      margin-left: 25px;
    }
  }

  .System_Button_Numbers:hover{
    background-color: #f9b116;
    border-color: #f9b116;
  }

  .MatchOdds_FirstCell{
    width: 210px;
    @media screen and (max-width: 992px) {    
      width: 150px;
    }
  }

  .cardBodyStyle {
    height: calc(var(--innerHeight) - var(--header_height));
    @media screen and (max-width: 992px) {    
      height: calc(var(--innerHeight) - var(--small_header_height));
    }
  }

  .MainCardHeight {
    margin: 0px;
    height: calc(var(--innerHeight) - var(--header_height) - var(--table_footer_height));
    @media screen and (max-width: 992px) {    
      height: calc(var(--innerHeight) - var(--small_header_height) - var(--small_table_footer_height));
    }
  }

  .MainCardBodyHeight {
    padding: 0px;
    background-color: #f0f5f0ad;
    overflow-y: scroll;
    height: calc(var(--innerHeight) - var(--header_height) - var(--table_footer_height));
    @media screen and (max-width: 992px) {    
      height: calc(var(--innerHeight) - var(--small_header_height) - var(--small_table_footer_height));
    }
  }

  .MainTableContainer{
    overflow-y: scroll;
    height: calc(var(--innerHeight) - var(--header_height) - var(--table_footer_height) - 2px);
    @media screen and (max-width: 992px) {    
      height: calc(var(--innerHeight) - var(--small_header_height) - var(--small_table_footer_height) - 2px);
    }
  }

  .MainFinalRow{
    align-items: center;
    height: var(--table_footer_height);
    background-color: #dddddd;
    @media screen and (max-width: 992px) {    
      height: var(--small_table_footer_height);
    }
  }
}

.MuiTableCell-root.hide_this_Cell{
  @media screen and (max-width: 992px) {
    display: none;
  }
}

.c-sidebar-nav-divider {
  background-color: white;
  height: 1px;
  margin-right: 0px;
  margin-left: 0px;
}
.MuiTableCell-root.CollapseRowButtonCell{
  padding-right: 16px;
  @media screen and (max-width: 992px) {
    padding-right: 8px;
    font-size: small;
  }
}

.MuiTableRow-root.CollapseRowTableRow{
  background-color: #27722f;
}

.CollapseRowButtons {
  text-align-last: justify;
  @media screen and (max-width: 992px) {
    font-size: xx-small;
  }
}

.MuiTableCell-body.CollapseRowGreenTableCell {
  color: #ffffff;
  @media screen and (max-width: 992px) {
    font-size: small;
  }
}

.MatchOddsSpan {
  color: black;
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding-left: 26px;
  padding-right: 10px;
  @media screen and (max-width: 992px) {
    padding-left: 0px;
  }
}

.MuiIconButton-root.TheLockCodeButton:focus {
  outline-width: 0px !important;
}


.card-body{
  padding: 1.25rem;
  @media screen and (max-width: 992px) {
    padding-left: 0px;
    padding-right: 0px;
  }
}

// .MuiFormControl-fullWidth{
//   min-width: 180% !important;
// }


// fieldset{
//   border: none !important;
// }

.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-sizeSmall.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root{
  cursor: default;
}
.css-gtwism-MuiAutocomplete-root .MuiAutocomplete-input{
  cursor: default;
}


.dropdown-menu.show.DropDownMenuUser{
  margin-top: 10px !important;
}


.CollapseRowHeadline {
  margin-bottom: 0px;
  @media screen and (max-width: 992px) {
    font-size: 1rem;
  }
}

.CollapseRowCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 992px) {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}

.img_table {
  height: 25px,;
  width: 40px;
  border: 0.1px solid;
  border-color: black;
}

.MuiTableCell-root.Time_Date_Cell {
  width: 70px;
  padding: 6px 24px 6px 16px ;
  // font-size: inherit;
  @media screen and (max-width: 992px) {
    width: 40px;
    padding: 6px;
    font-size: smaller;
  }
}

.MuiTableCell-root.CategoriesTableCell {
  padding: 2px;
}

.disapear_when_small{
  @media screen and (max-width: 992px) {
    display: none;
  }
}


.MuiButtonBase-root.appear_when_small{
  display: none;
  @media screen and (max-width: 992px) {
    display: inherit;
  }
}

.label.disapear_when_small{
  @media screen and (max-width: 992px) {
    display: none;
  }
}

.MuiButtonBase-root.disapear_when_small {
  @media screen and (max-width: 992px) {
    display: none;
  }
}

.CategoriesMainButtons_div{
  text-align: right;
  @media screen and (max-width: 992px) {
    text-align: center;
  }
}

.CategoriesMainButtons {
  margin: 0.3rem;
  @media screen and (max-width: 992px) {
    margin: 0.1rem;
    padding: 3px 5px;
  }
}

.CategoriesMainButtons_h3 {
  margin: 0rem;
  @media screen and (max-width: 992px) {
    font-size: 1rem;
  }
}


.Sport_Country_League_span {
  // width: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  max-width: 400px;
}

.Sport_Country_League_subspan1 {
  // max-width: 100px;
  font-size: 12px;
  @media screen and (max-width: 992px) {
    font-size: 10px;
  }
}

.Sport_Country_League_subspan2 {
  font-weight: 900;
  // max-width: 100px;
  font-size: 15px;
  @media screen and (max-width: 992px) {
    font-size: 12px;
  }
}

.Modal_spinner .modal-content {
  width: 21em;
  padding: 2em;
  background-color: #dae9db;
  @media screen and (max-width: 992px) {
    width: 15em;
    padding: 1em;
  }
}


.Modal_spinner.d-block {
  display: flex !important;
  justify-content: center;
}

.Spinner_style {
  animation-duration: 2.0s;
  border-width: 1.5em;
  width: 17em;
  height: 17em;
  @media screen and (max-width: 992px) {
    width: 13em;
    height: 13em;
    border-width: 1em;
  }
}

.button_zero_padding{
  padding-right: 0px !important;
  padding-left: 0px !important;
  width: 45px;
  @media screen and (max-width: 992px) {
    width: 35px;
    font-size: x-small;
  }
}

.button_zero_padding.button_left {
  margin-right: 10px;
  @media screen and (max-width: 992px) {
    margin-right: 3px;
  }
}

.MuiFormControl-root.dropdown_header_main_markets {
  margin-right: 50px;
  min-width: 150px;
  max-width: 350px;
  overflow: ellipsis;
  @media screen and (max-width: 992px) {
    display: none;
  }
}

.MuiFilledInput-root {
  background-color: #dae9db !important;
  border: 2px solid black;
  border-radius: 5px;
  max-width: 350px;
}

.MuiSelect-filled.MuiSelect-filled{
  height: 30px;
  padding: 0px 16px;
  display: flex;
  align-items: center;
}

.MuiFilledInput-underline::after{
  border-bottom: 2px !important;
}


.button_header_main_markets {
  margin-right: 10px;
  @media screen and (max-width: 992px) {
    display: none;
  }
}

.column_right_left_0{
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.card{ 
  border-radius: 0% !important;
  margin-bottom: 0px;
}

.dropdown-menu.show{
  transform: translate3d(0px, 28px, 0px) !important;
  display: flex !important;
  flex-wrap: wrap !important;
}

.columns_5_padding{
  padding: 5px;
}


.badge_modifications {
  border-radius: 50%;
  padding: 0.3em;
  margin-right: 5px;
  font-size: 12px;
  @media screen and (max-width: 992px) {
    display: none;
    font-size: 0.875rem;
  }
}

.badge_modifications_collapse {
  border-radius: 50%;
  padding: 0.3em;
  margin-right: 5px;
  font-size: 12px;
  // @media screen and (max-width: 992px) {
  //   display: none;
  //   font-size: 0.875rem;
  // }
}

.close {
  font-weight: 1000;
  font-size: 1.9rem;
  opacity: 1;
}
.row {
  margin-left: 0px;
  margin-right: 0px;
}
.TicketsShowSlipsHeadCells {
  color: white !important;
  border-right: 1px solid black !important;
  border-bottom: 0px !important
}
.TicketsShowSlipsBodyCells {
  border: 1px solid
}
.superSmallFont {
  font-size: small;
}